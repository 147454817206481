<template>
  <div class="tc_userInfo">
    <div class="tc_userInfo-layout">
      <!-- 头部标题 -->
      <div class="content">
        <div class="userInfo-header">
          <p>个人中心</p>
        </div>
        <!-- 头像 -->
        <div class="headImg">
          <el-upload
            class="avatar-uploader"
            action="/upload_api"
            :show-file-list="false"
            :on-success="fileUploadApi"
            :before-upload="beforeAvatarUpload"
            :data="fileData"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <!-- 个人信息 -->
        <div class="info">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="85px"
            size="mini"
            status-icon
          >
            <!-- 个人信息 -->
            <el-collapse v-model="activeNames">
              <el-collapse-item title="个人信息" name="1">
                <el-form-item label="账号:" prop="account">
                  <span>{{ form.account }}</span>
                </el-form-item>
                <!-- <el-form-item label="门诊号/住院号:" prop="account">
                  <span>{{ form.account }}</span>
                </el-form-item> -->
                <el-form-item label="用户姓名:" prop="real_name">
                  <el-input
                    v-model="form.real_name"
                    maxlength="12"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="身份证号:" prop="idcard">
                  <el-input
                    v-model="form.idcard"
                    maxlength="18"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="性别:" prop="sex">
                  <el-radio-group v-model="form.sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="0">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="出生日期:" prop="age">
                  <el-date-picker
                    :picker-options="pickerOptions0"
                    v-model="form.age"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item> -->
                <el-form-item label="年龄" prop="ages">
                  <el-input
                    type="number"
                    v-model="form.ages"
                    maxlength="16"
                    :min="0"
                  ></el-input>
                </el-form-item>
                <el-form-item label="文化程度:" prop="culture">
                  <el-select v-model="form.culture">
                    <el-option label="文盲" value="文盲"></el-option>
                    <el-option label="小学" value="小学"></el-option>
                    <el-option label="初中" value="初中"></el-option>
                    <el-option
                      label="高中/中专/技校"
                      value="高中/中专/技校"
                    ></el-option>
                    <el-option label="大学专科" value="大学专科"></el-option>
                    <el-option label="大学本科" value="大学本科"></el-option>
                    <el-option label="硕士" value="硕士"></el-option>
                    <el-option
                      label="博士及以上"
                      value="博士及以上"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="科室:" prop="department_id">
                  <el-select v-model="form.department_id">
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in departmentList"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-collapse-item>
              <!-- 修改密码 -->
              <el-collapse-item title="修改密码" name="2">
                <el-form-item label="原密码:" prop="oldpassword">
                  <el-input
                    v-model="form.oldpassword"
                    maxlength="16"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="新密码:" prop="password">
                  <el-input
                    v-model="form.password"
                    maxlength="16"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-collapse-item>
              <!-- 绑定信息 -->
              <el-collapse-item title="其他信息" name="3">
                <!-- 自填信息 -->
                <template v-for="item in archivesData">
                  <!-- 单行文本 -->
                  <template v-if="item.type === 1">
                    <el-form-item :label="item.name + ':'" :key="item.id">
                      <el-input v-model="form.archives[item.key]"></el-input>
                    </el-form-item>
                  </template>
                  <!-- 下拉框 -->
                  <template v-if="item.type === 3">
                    <el-form-item :label="item.name + ':'" :key="item.id">
                      <el-select v-model="form.archives[item.key]">
                        <el-option
                          :label="item2"
                          :value="item2"
                          v-for="(item2, key) in item.content"
                          :key="key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                  <!-- 单选 -->
                  <template v-if="item.type === 4">
                    <el-form-item :label="item.name + ':'" :key="item.id">
                      <el-radio-group v-model="form.archives[item.key]">
                        <el-radio
                          :label="item2"
                          v-for="(item2, key) in item.content"
                          :key="key"
                        ></el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </template>
                  <!-- 复选 -->
                  <template v-if="item.type === 5">
                    <el-form-item :label="item.name + ':'" :key="item.id">
                      <el-checkbox-group v-model="form.archives[item.key]">
                        <el-checkbox
                          :label="item2"
                          v-for="(item2, key) in item.content"
                          :key="key"
                        ></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </template>
                  <!-- 文本域 -->
                  <template v-if="item.type === 2">
                    <el-form-item :label="item.name + ':'" :key="item.id">
                      <el-input
                        type="textarea"
                        v-model="form.archives[item.key]"
                        :rows="5"
                      ></el-input>
                    </el-form-item>
                  </template>
                </template>
              </el-collapse-item>
            </el-collapse>
            <!-- 按钮 -->
            <el-row type="flex" justify="center" class="btn">
              <el-button type="primary" @click="back">返回</el-button>
              <el-button type="primary" @click="updataUser">保存</el-button>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserDepartment, getuserinfo, saveInfo } from "@/api/comm.js";
// import { async } from "q";
export default {
  data() {
    var validateIdc = (rule, value, callback) => {
      let reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
      if (value === "") {
        callback(new Error("身份证不能为空"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的身份证号码"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        // idcard: [
        //   {
        //     required: false,
        //     min: 18,
        //     max: 18,
        //     message: "身份证号长度不符",
        //     trigger: "blur",
        //   },
        //   { validator: validateIdc, trigger: ["blur", "change"] },
        // ],
        oldpassword: [
          {
            required: true,
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        real_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        age: [{ required: true, message: "请选择出生日期", trigger: "blur" }],
        // ages: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        department_id: [
          { required: true, message: "请选择科室", trigger: "change" },
        ],
      },
      form: {
        type: 1,
        account: "",
        idcard: "",
        sex: "",
        name: "",
        oldpassword: "", //原密码
        password: "", //新密码
        culture: "",
        head_id: "",
        age: "",
        ages: "",
        department_id: "",
        real_name: "",
        archives: {},
      },
      archivesData: [],
      status: 0,
      fileData: {
        type: 0,
        attribution_id: 1, // 当前 用户的id
      },
      imageUrl: "./static/img/regtx.png",
      departmentList: [],
      activeNames: ["1"],
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //只能选择今天及今天之前
        },
      },
    };
  },
  created() {
    //   获取用户信息
    getuserinfo().then((res) => {
      //console.log(res);
      let {
        account,
        real_name,
        sex,
        age,
        idcard,
        ages,
        culture,
        department_id,
        haderurl,
        head_id,
        archives,
      } = res.data;
      this.form.account = account;
      this.form.real_name = real_name;
      this.form.sex = sex;
      this.form.age = age;
      this.form.idcard = idcard;
      this.form.ages = ages;
      this.form.head_id = head_id;
      this.form.department_id = department_id;
      this.imageUrl = haderurl == "" ? "./static/img/regtx.png" : haderurl;
      this.form.culture = culture;
      // 自定义档案处理
      this.archivesData = archives;
      archives.forEach((v, k) => {
        let data = {};
        if (v.value != "") {
          if (v.type === 3 || v.type === 5) {
            this.$set(this.form.archives, v.key, v.value || []);
          } else {
            this.$set(this.form.archives, v.key, v.value);
          }
        }
      });
    });
    // 获取科室
    getUserDepartment().then((res) => {
      if (res.code === 400200) {
        this.departmentList = res.data.data;
      }
    });
  },
  methods: {
    // 文件上传成功的狗子
    fileUploadApi(response, file) {
      this.form.head_id = response.data.id;
      this.form.p;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 图片上次校验
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      const isLt50M = file.size / 1024 / 1024 < 2;
      if (!extension) {
        this.$message({
          message: "上传图片只能是jpg / png / bpm格式!",
          type: "error",
        });
        return false;
      }
      //console.log(file);
      if (!isLt50M) {
        this.$message({
          showClose: true,
          message: "上传文件大小不能超过 2MB!",
          type: "error",
        });
        return false;
      }
      return extension || isLt50M;
    },
    // 保存信息
    updataUser() {
      //console.log(this.form);
      if (this.form.oldpassword == "") {
        delete this.form.oldpassword;
        delete this.form.password;
      }
      // 上传的时候用ages
      this.form.age = this.form.ages;
      saveInfo(this.form).then((res) => {
        //console.log(res);
        if (res.code === 400200) {
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success",
          });
        }
      });
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>
<style lang="less">
.tc_userInfo {
  width: 100%;
  zoom: 1;
  box-sizing: border-box;
  height: 100%;
  .tc_userInfo-layout {
    max-width: 768px;
    min-width: 325px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    .content {
      .userInfo-header {
        text-align: center;
        background: rgb(217, 237, 238);
        justify-content: center;
        p {
          font-size: 25px;
          color: rgb(37, 37, 37);
          line-height: 50px;
          font-weight: 700;
          text-shadow: #ffffff 2px 2px;
          box-shadow: black 0px 2px 5px;
        }
      }
      box-shadow: black 0px 2px 15px;
      // height: calc~ "(100% - 50px)";
      flex: 13;
      // height: 100%;
      // 头像
      background: white;
      .headImg {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        .avatar-uploader .el-upload {
          border: 1px solid rgb(38, 103, 114);
          border-radius: 50px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 80px;
          height: 80px;
          line-height: 80px;
          text-align: center;
        }
        .avatar {
          width: 80px;
          height: 80px;
          display: block;
        }
      }
      .info {
        padding: 0px 20px;
        padding-top: 20px;
        .btn {
          margin-top: 20px;
        }
        // 抽屉
        .el-collapse {
          .el-collapse-item__header {
            border-top: 1px rgb(38, 103, 114) solid;
            background: transparent;
          }
          .el-collapse-item__wrap {
            background: transparent;
          }
        }
      }
      .el-form-item.is-required:not(.is-no-asterisk)
        > .el-form-item__label:before {
        display: none;
      }
    }
  }
}
</style>